import React from 'react'
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' */
import PropTypes from 'prop-types'
import style from './CardsProducts.module.scss'
import Img from 'gatsby-image'

class CardsProducts extends React.Component {
  static propTypes = {
    cardInfo: PropTypes.object,
    btnMore: PropTypes.string,
    images: PropTypes.object,
  }

  render() {
    const { cardInfo /* , btnMore */, images } = this.props

    return (
      <div className={style.card}>
        <Img sizes={images} />
        <div className={style.body}>
          <h4 dangerouslySetInnerHTML={{ __html: cardInfo.label || 'Название' }} className={style.title} />
          <p dangerouslySetInnerHTML={{ __html: cardInfo.text || 'Текст' }} className={style.text} />
        </div>
        {/*         <div className={style.footer}>
          <p
            dangerouslySetInnerHTML={{
              __html: btnMore || 'Подробнее',
            }}
            className={style.btn}
          />
          <FontAwesomeIcon
            size="3x"
            icon={['fas', 'arrow-right']}
            transform="shrink-8"
            color="#3e7af8"
          />
        </div> */}
      </div>
    )
  }
}

export default CardsProducts
