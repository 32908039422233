import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import style from './ProductsPage.module.scss'
import Products from './components/Products'

class ProductsPage extends React.Component {
  static propTypes = {
    images: PropTypes.array,
  }

  render() {
    return (
      <React.Fragment>
        <div className={classnames(style.productPage, style.container, style.basicBlock)}>
          <Products images={this.props.images} />
        </div>
      </React.Fragment>
    )
  }
}

export default ProductsPage
