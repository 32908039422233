import React, { PureComponent } from 'react'
import style from '../ProductsPage.module.scss'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import CardsProducts from '../../../components/cards/CardsProducts'

class Products extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: props.intl.messages.productsPage.products,
    }
  }

  static propTypes = {
    intl: PropTypes.object,
    images: PropTypes.array,
  }

  render() {
    const { data } = this.state
    const { images } = this.props
    return (
      <div id="products" className={classnames(style.productsContainer, style.container)}>
        <h1 dangerouslySetInnerHTML={{ __html: data.heading }} />
        <div className={style.innerContainer}>
          <div className={style.cardGroup}>
            {data.cards.map((x, i) => (
              <CardsProducts
                key={`card-${i}`}
                btnMore={data.btnMore}
                cardInfo={x}
                images={images[i].node.childImageSharp.sizes}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(Products)
